import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/groups'
import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { ColorType } from 'components/common/types'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type GroupsState = {
  isRequesting: boolean
  errorMessage: string
  group?: API.GroupData
  groups: API.GroupData[]
  groupWorkspaceId: number | undefined
}

const initialState: GroupsState = {
  isRequesting: false,
  errorMessage: '',
  group: undefined,
  groups: [],
  groupWorkspaceId: undefined,
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getGroupListSuccess: (state, action: PayloadAction<API.GroupListResponse>) => {
      state.isRequesting = false
      state.groups = _.sortBy(action.payload.workGroups, 'name')
      state.groupWorkspaceId = action.payload.partialWorkspace.id
    },
    getGroupSuccess: (state, action: PayloadAction<API.GroupResponse>) => {
      state.isRequesting = false
      state.group = action.payload.workGroup
    },
    createGroupSuccess: state => {
      state.isRequesting = false
    },
    updateGroupSuccess: state => {
      state.isRequesting = false
    },
    deleteGroupSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getGroupListSuccess,
  getGroupSuccess,
  createGroupSuccess,
  updateGroupSuccess,
  deleteGroupSuccess,
} = groupsSlice.actions

export const getGroupList =
  (workspaceId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getGroupList(commonParams(getState), workspaceId)
      .then((res: API.GroupListResponse) => dispatch(getGroupListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getGroup =
  (workspaceId: number, groupId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getGroup(commonParams(getState), workspaceId, groupId)
      .then((res: API.GroupResponse) => dispatch(getGroupSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createGroup =
  (workspaceId: number, name: string, color: ColorType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createGroup(commonParams(getState), workspaceId, name, color)
      .then(() => {
        dispatch(createGroupSuccess())
        dispatch(getGroupList(workspaceId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateGroup =
  (workspaceId: number, groupId: number, name: string, color: ColorType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateGroup(commonParams(getState), workspaceId, groupId, name, color)
      .then(() => {
        dispatch(updateGroupSuccess())
        dispatch(getGroupList(workspaceId))
        dispatch(getGroup(workspaceId, groupId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteGroup =
  (workspaceId: number, groupId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteGroup(commonParams(getState), workspaceId, groupId)
      .then(() => {
        dispatch(deleteGroupSuccess())
        dispatch(getGroupList(workspaceId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectGroupsStatus = (state: RootState) => ({ ...state.groups })

export default groupsSlice.reducer
